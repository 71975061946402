/* global L, d3, $, localStorage, ga, URL, history, gon */

let map
let gz  // Gleitzahl
let startAltitude = 0;  // 0 means on surface
let flightcone_data = {}
let flightcone_params = {}
let squares = []
let flightPath
let r_lat, r_lng
let startCircle, endCircle
let viewport_params  // center_lat, center_lng, zoom_level, active_layer

const show_elevation = false  // turn on to show model elevation
const chBounds = L.latLngBounds(L.latLng(45, 5), L.latLng(48, 11))  // Switzerland
let current_layer_name
let layer_control, search_button, search_control
let opentopomap, satellitemap, swisstopomap, swissimagemap
let in_switzerland = false

const color_scaling = 300;
const colorScale = d3.scaleLinear()
                   .domain([0,1,2,3,4].map(e => e * color_scaling))
                   .range(["#d7191c","#fdae61","#ffffbf","#abd9e9","#2c7bb6"]);

// print color scale information for server rendering of kml file
// console.log(colorScale.domain())
// console.log(colorScale.ticks(100))
// console.log(colorScale.ticks(100).map(c => d3.color(colorScale(c)).formatHex()))

function initialize() {
  map = L.map('map-canvas', {
    minZoom: 3, maxZoom: 17,
    zoomControl: false,
    worldCopyJump: true, // reset longitude when crossing dateline
  })
  L.control.sidebar('sidebar', {position: 'right'}).addTo(map)

  L.control.locate({
    icon: 'fas fa-map-marker-alt',
    position: 'topleft',
  }).addTo(map)
  search_button = L.easyButton('fa-search', function(control) {
    search_control.addTo(map);
    }, 'Search location', {position: 'topleft'}
  )
  search_button.addTo(map);
  search_control = L.control.photon({
    position: 'topleft',
    url: 'https://photon.komoot.io/api/?',
    placeholder: 'Search map...',
    // includePosition: true,
    feedbackEmail: null,
    onSelected: function (feature) {
      this.map.setView([feature.geometry.coordinates[1], feature.geometry.coordinates[0]], 15);
      search_control.remove();
    },
  });
  // search_control.addTo(map);

  L.control.scale({position: 'bottomleft'}).addTo(map)

  L.easyButton('far fa-trash-alt', function(btn, map){
    callReset()
  }, 'Clear map', {position: 'bottomright'}).addTo(map)
  L.control.zoom({position: 'bottomright'}).addTo(map)

  // initialize viewport
  // 1. priority have url params lat, lng, zoom
  // 2. priority is values in local storage
  // 3. priority is default values
  viewport_params = JSON.parse(localStorage.viewport_params || null)  // null masks ''
                    || {lat: 47, lng: 8.5, zoom: 9}  // default values
  let url = new URL(window.location.href);
  if (url.searchParams.get("lat")) viewport_params.lat = Number(url.searchParams.get("lat"))
  if (url.searchParams.get("lng")) viewport_params.lng = Number(url.searchParams.get("lng"))
  if (url.searchParams.get("zoom")) viewport_params.zoom = Number(url.searchParams.get("zoom"))
  map.setView(L.latLng(viewport_params.lat, viewport_params.lng), viewport_params.zoom)

  opentopomap = L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
      attribution: '© <a href="https://openstreetmap.org/copyright">OpenStreetMap</a>, SRTM | © <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
  })
  satellitemap = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
    attribution: '<a href="https://www.esri.com/">Esri</a>,' +
                 ' i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP ' +
                 ' and the GIS user community.'
    // see https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer
    // ArgGIS developer account darndt2
  })
  // swisstopomap = L.tileLayer(
  //   'https://wmts10.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe/default/current/3857/{z}/{x}/{y}.jpeg',
  //   // 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  //   {
  //   attribution: '© <a href="https://www.swisstopo.admin.ch/">Swisstopo</a>',
  // })
  swisstopomap = L.tileLayer.wms('https://wms.geo.admin.ch/?', {
			layers: 'ch.swisstopo.pixelkarte-farbe',
			format: 'image/jpeg',
			detectRetina: true,
	  }
	)
  swissimagemap = L.tileLayer.wms('https://wms.geo.admin.ch/?', {
			layers: 'ch.swisstopo.swissimage',
			format: 'image/jpeg',
			detectRetina: true,
	  }
	)

  switch(localStorage.active_layer) {
    case 'Topo':
      opentopomap.addTo(map)
      break;
    case 'Satellite':
      satellitemap.addTo(map)
      break;
    case "Swisstopo🇨🇭":
      swisstopomap.addTo(map)
      break;
    case "Swissimage🇨🇭":
      swissimagemap.addTo(map)
      break;
    default:
      opentopomap.addTo(map)
  }

  layer_control = L.control.layers({
		"Topo": opentopomap,
		"Satellite": satellitemap,
		"Swisstopo🇨🇭": swisstopomap,
		"Swissimage🇨🇭": swissimagemap,
	}, null, {
	  position: 'topright',
	  collapsed: true,
	  sortLayers: true,
	  sortFunction: function (layerA, layerB, nameA, nameB) {
	    var preferredOrder = ["Topo", "Satellite", "Swisstopo🇨🇭", "Swissimage🇨🇭"];
      return preferredOrder.indexOf(nameA) - preferredOrder.indexOf(nameB);
    },
	})
	layer_control.addTo(map)


  draw_legend()

  // initialize gz
  gz = JSON.parse(localStorage.gz || null) || 6
  $("#gzMenu").val(gz.toString())

  $("#reset-button" ).click(() => { callReset() })
  $('#gzMenu, #startAltitudeMenu').change(() => {
    userParametersChanged()
  })

  map.on('moveend', (e) => {
    $('#info').html(flight_params_infobox())
    // save viewport to local storage
    let c = map.getCenter()
    console.log(map.getCenter())
    let z = map.getZoom()
    localStorage.viewport_params = JSON.stringify(
      {lat: c.lat, lng: c.lng, zoom: z}
    )
    history.replaceState({}, "", `?lat=${c.lat}&lng=${c.lng}&zoom=${z}`);
  })
  map.on('baselayerchange', (e) => {
    current_layer_name = e.name
    localStorage.active_layer = e.name
  })

  map.on('singleclick', (e) => { clickOnMap(e) })

  // set marker
  if (url.searchParams.get("lat") && url.searchParams.get("lng")
      && url.searchParams.get("marker")=='1') {
    L.marker(
      [viewport_params.lat, viewport_params.lng],
      {bubblingMouseEvents: true}
    ).addTo(map)
    clickOnMap({latlng: L.latLng([viewport_params.lat, viewport_params.lng])})
  }

  // // for debugging
  // map.addEventListener('mousemove', function(ev) {
  //   console.log(ev.latlng);
  // });

}


function clickOnMap(e) {
  $('#map-canvas-cover').show()
  $('#info').html(
    'Calculating...&emsp;<div class="spinner-border spinner-border-sm">'
  )
  reset()
  let start = [e.latlng.lat, e.latlng.lng]
  // var startAltitude = Number($('#startAltitudeMenu').find(":selected").val())
  let url_params = `?lat=${start[0]}&lon=${start[1]}` +
                   `&start_altitude=${startAltitude}&gz=${gz}`
  let url = "flight_cone" + url_params
  let kmlurl = "flight_cone.kml" + url_params
  fetch(url)
    .then( (response) => {
       if (!response.ok) throw Error(response.statusText)
       $('#info').html('Drawing...&emsp;<div class="spinner-border spinner-border-sm">')
       return response.json()
     })
    .then( (data_) => {
       if (data_['status'] == 'error') throw Error(data_['error'])

       flightcone_params = data_['params']

       var altitude_start = data_['params']['start_altitude']
       $("#startalt").html(Math.round(altitude_start))

       var elevation_start = data_['params']['start_elevation']
       r_lat = data_['params']['d_lat'] / 2  // "radius" for lonlat "rectangle"
       r_lng = data_['params']['d_lon'] / 2

       flightcone_data = data_['data']
       // console.log(flightcone_data)
       draw_altitude()

       startCircle = L.circle(start, 200*0.8, {
         color: altitude_start > elevation_start ? '#ff0000' : '#222222',
         weight: 1,
         fillColor: altitude_start > elevation_start ? '#ffcccc' : '#ffffff',
         fillOpacity: 0.8,
       })
       startCircle.addTo(map)

       $('#map-canvas-cover').hide()
       $('#info').html(flight_params_infobox())
       if (typeof ga != 'undefined') ga('send', 'event', 'Map', 'Set start point')
       $("#kmllink").html(
        `<a download="parange_export.kml" href="${kmlurl}">` +
        `Download as KML</a> and drop into Google Earth`
        )

     })
     .catch(function(error) {
       console.log(error);
       $('#info').html('Error: ' + error)
       $('#map-canvas-cover').hide()
     })
}

function draw_legend() {
  let svg = d3.select("#color-scale").append("svg")
              .attr("width", 90).attr("height", 165)
  let margin = 10
  let legend = svg.selectAll(".legend")
        .data(colorScale.ticks(5).reverse())
      .enter().append("g")
        .attr("transform", (d, i) => "translate(" + margin + "," + (20 + i * 20) + ")")

  legend.append("rect")
      .attr("width", 20).attr("height", 20)
      .style("fill", colorScale)

  legend.append("text")
      .attr("class", "scale-label")
      .attr("x", 69).attr("y", 10)
      .attr("dy", ".35em")
      .text((d, i) => d+" m")

  svg.append("text")
      .attr("class", "scale-header")
      .attr("x", margin).attr("y", 10)
      .attr("dy", ".35em")
      .text("AGL")
}


function draw_altitude() {
  for (let pos_s of Object.keys(flightcone_data)) {
    let [lat, lng] = pos_s.split(",").map(e => Number(e))
    let [altitude, altitude_above_ground,,max_reached] = flightcone_data[pos_s]

    let square
    if (max_reached) {
      square = L.rectangle([[lat-r_lat, lng-r_lng], [lat+r_lat, lng+r_lng]], {
        weight: 1.0, color: '#ffffff', opacity: 1.0,
        fillColor: '#a142f5', fillOpacity: 0.9,
      })
    } else {
      let color = colorScale(show_elevation ? altitude-altitude_above_ground : altitude_above_ground)
      let opacity = 0.5
      square = L.rectangle([[lat-r_lat, lng-r_lng], [lat+r_lat, lng+r_lng]], {
        weight: 0.5, color: color, opacity: opacity,
        fillColor: color, fillOpacity: opacity,
      })
    }
    square.addTo(map)

    square.on('mouseover', function(pos_s, altitude, altitude_above_ground, max_reached) {
      return () => {
        const [lat, lng] = pos_s.split(",").map(e => Number(e))

        // calculate flight distance and path
        let distance = 0.0
        let path = []
        let current_s = pos_s
        while (current_s != null) {
          distance += flightcone_data[current_s][4]
          path.push(current_s.split(",").map(e => Number(e)))
          current_s = flightcone_data[current_s][2]
        }

        // endCircle
        if (endCircle != undefined) endCircle.remove()
        endCircle = L.circle([lat, lng], 200*0.8, {
          color: '#ffffff', weight: 1,
          fillColor: '#222222', fillOpacity: 0.8,
        })

        // tooltip
        let tooltip_agl = max_reached ?
          'max reached' : Math.round(altitude_above_ground)+' m AGL'
        let tooltip_distance = (distance/1000).toFixed(1) + ' km'
        let tooltip_text = tooltip_agl + "<br/>" + tooltip_distance
        endCircle.bindTooltip(tooltip_text, {
          direction: 'top',
          offset: L.point(0,-12),
          sticky: true,
          className: 'altitude-tooltip'
        })
        endCircle.on('click', (e) => {
          L.DomEvent.stopPropagation(e);
        })
        endCircle.addTo(map)

        // flight path line
        path.reverse()  // so the line starts at start
        // console.table(path)
        if (flightPath != undefined) flightPath.remove()
        flightPath = L.polyline(path, {
          color: '#000000', opacity: 1.0,
          dashArray: '4 7',
        })
        flightPath.addTo(map)
        flightPath.setText('        \u25BA ', {
          repeat: true,
          offset: 7,
          attributes: {fill: '#000000', 'font-size': '20'}
        })
        $("#endalt").html(Math.round(altitude))
        $("#endaag").html(Math.round(altitude_above_ground))
        $("#ground").html(Math.round(altitude - altitude_above_ground))
      }
    }(pos_s, altitude, altitude_above_ground, max_reached))
    square.on('click', (e) => {
      L.DomEvent.stopPropagation(e);
    })
    squares.push(square);
  }
}


function reset() {
  $('#startalt, #endalt, #endaag, #ground, #kmllink').html('')
  if (startCircle != undefined) startCircle.remove()
  if (endCircle != undefined) endCircle.remove()
  if (flightPath != undefined) flightPath.remove()
  for (let i=0; i < squares.length; i++) squares[i].remove()
  flightcone_data = []
  squares = []
}


function callReset() {
  $('#map-canvas-cover').show()
  reset()
  $('#map-canvas-cover').hide()
}

function userParametersChanged() {  // gleitzahl or start altitude changed
  // save gz
  gz = Number($('#gzMenu').find(":selected").val())
  localStorage.gz = JSON.stringify(gz)
  startAltitude = Number($('#startAltitudeMenu').find(":selected").val())

  $('#info').html(flight_params_infobox());

  if (Object.keys(flightcone_data).length > 0) {  // a start point is set currently
    let start_pos = flightcone_params['start_pos']  // remember start value
    callReset()
    // rerun simulation
    clickOnMap({latlng: L.latLng(start_pos)})
  }
}

function flight_params_infobox() {
  return `${gz} : 1` + (startAltitude > 0 ? `, Start @ ${startAltitude}m` : '')
}


module.exports = {
  initialize: initialize,
}
