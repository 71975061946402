// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()

// import "bootstrap" . // don't need bootstrap javascript for this project
import "../stylesheets/application"

import * as d3 from "d3"
window.d3 = d3
// import {select as d3Select} from "d3-selection"
// window.d3Select = d3Select
// import {scaleLinear as d3ScaleLinear} from "d3-scale"
// window.d3ScaleLinear = d3ScaleLinear

import L from "leaflet"
require("leaflet/dist/leaflet.css")
// stupid hack so that leaflet's images work after going through webpack
import marker from 'leaflet/dist/images/marker-icon.png'
import marker2x from 'leaflet/dist/images/marker-icon-2x.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
    iconRetinaUrl: marker2x,
    iconUrl: marker,
    shadowUrl: markerShadow
})

import 'leaflet.locatecontrol'
require("leaflet.locatecontrol/dist/L.Control.Locate.css")

import 'leaflet-easybutton'
require("leaflet-easybutton/src/easy-button.css")

import 'leaflet.photon'
require("leaflet.photon/leaflet.photon.css")

import '../src/Leaflet.singleclick.js'
import '../src/Leaflet.textpath.js'

import 'sidebar-v2/js/leaflet-sidebar.js'
require('sidebar-v2/css/leaflet-sidebar.css')

// see here: https://medium.freecodecamp.org/javascript-modules-a-beginner-s-guide-783f7d7a5fcc
// to access varables in chrome console use 'debugger'
var parange = require('../src/parange.js')
document.addEventListener("DOMContentLoaded", () => {
  parange.initialize();
})





// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
